<template>

  <b-tab title-item-class="tab-title" :active="condition" @click="onTabChange">
      <template slot="title">
        <div class="title-container">
          <span class="title-span">{{ collection_title }}</span>
          <span v-if="in_progress"><PulseLoader :color="theme_color.main" size="5px"/></span>
          <span v-else>
             ({{ total }}) 
          </span>
        </div>
      </template>

      <b-card-text>

          <div v-if="in_progress" class="progress-container">
            <PulseLoader :color="theme_color.main"/>
          </div>

          <div v-else class="content-container">

            <FacetsComponent  v-show="user_query.mode!='id'" class="facets-container" :collection_name="collection_name" :documents="search_results[collection_name]" :filters="filters.documents" :user_selection="user_selection.documents" :item_size="3" :updateFacet="updateDocumentsFacet" :updateSort="updateDocumentsSort"/>

            <div class="full-container">

              <div class="error-container" v-if="error">{{ error }}</div>

              <ResultsSearchDocuments v-else :documents="search_results[collection_name]" :collection_name="collection_name" :collection_title="collection_title" :document_name="document_name"/>

            </div> 

          </div>

      </b-card-text>

  </b-tab>

</template>

<script>

  import { mapState, mapActions } from 'vuex'
  import axios from 'axios';
  import FacetsComponent from '@/components/FacetsComponent.vue'
  import ResultsSearchDocuments from '@/components/ResultsSearchDocuments.vue'
  var PulseLoader = require('vue-spinner/src/PulseLoader.vue').default;

  export default {
    name: 'ResultsCollection',
    components: {
      PulseLoader,
      FacetsComponent,
      ResultsSearchDocuments
    },
    props: {
      collection_name: {
          type: String,
          required: true
      },
      collection_title: {
          type: String,
          required: true
      },
      tab: {
        type: Number,
        default: 0
      },
      document_name : {
        type: String, 
        default: "document"
      }
    },
    data() {
        return {
          in_progress: false,
          total: null,
          error: null,
          condition: false
        };
      },
    computed: {
      ...mapState(['urls', 'user_query', 'search_results', 'user_selection', 'filters', 'theme_color', 'fields', 'doc_source']),
      cssVars () {
        return{
           '--color-main': this.theme_color.main,
        }
      }
    },
    methods: {
      //...mapActions(['updateSearchResults', 'updateDocumentsFacet', 'updateDocumentsSort', 'updateInitDateFilter', 'updateHighlightTerms', 'updateTab', 'updatePageTitle']),
      ...mapActions(['updateSearchResults', 'updateDocumentsFacet', 'updateDocumentsSort', 'updateInitDateFilter', 'updateTab', 'updateTabName']),
      search(){
        if(this.user_query.tab_selected_name == this.collection_name){
          this.updateTab(this.tab)
          this.condition = true
        }
        this.total = null
        this.in_progress = true
        this.updateSearchResults({'collection': this.collection_name, 'results': []})
        var url = this.urls['search']
        var final_query = {}
        final_query['query'] = {}
        var clauses = []
        
        
        // Ajout de la requête transformée
        if (this.user_query.transformed.query != null && this.user_query.transformed.query != ""){
          clauses.push(this.user_query.transformed.query)
        }
        // Ajout du date range 
        if ((this.user_query.date_min != null ||  this.user_query.date_max != null) && (this.collection_name == "medline" || this.collection_name == "pmc")){
          var date_names = []
          if (this.collection_name == "medline"){
            date_names = ["entrez_date"]
          }
          else if (this.collection_name == "pmc"){
            date_names = ["pmc_release_date", "publication_date"]
          }
          var ranges_query = {}
          ranges_query['bool'] = {}
          ranges_query['bool']['should'] = []
          for (var r=0; r<date_names.length; r++){
              var date_name = date_names[r]
              var range_query = {}
              range_query['range'] = {}
              range_query['range'][date_name] = {}
              if (this.user_query.date_min != null){
                range_query['range'][date_name]['gte'] = this.user_query.date_min
              }
              if (this.user_query.date_max != null){
                range_query['range'][date_name]['lte'] = this.user_query.date_max
            }
            ranges_query['bool']['should'].push(range_query)
          }
          clauses.push(ranges_query)
        }
        // Ajout de la source
        final_query["_source"] = this.fields[this.collection_name].split(",")
        if (clauses.length > 1) {
          final_query['query']['bool'] = {}
          final_query['query']['bool']['must'] = clauses
        }
        else {
          final_query['query'] = clauses[0]
        }

        this.cancel();
        let axiosSource = axios.CancelToken.source();
        this.request = { cancel: axiosSource.cancel };
        //alert(JSON.stringify(final_query))
        let body = {'jq': JSON.stringify(final_query), 'col': this.collection_name, 'n': this.user_query.search_size}
        axios
        .post(url, body, {
          cancelToken: axiosSource.token,
        })
            .then(response => {
              if ('success' in response.data && response.data.success == false){
                this.error = response.data.error
                this.total = 0
              }
              else {
                this.error = null
                var documents = response.data.elastic_output.hits.hits
                documents = this.processDate(documents)
                documents = this.processFacets(documents)

                this.total = documents.length
                this.updateSearchResults({'collection': this.collection_name, 'results': documents})
                this.selectTab()
              }
            })
            .catch(error => {
              if(error != "Cancel"){
                alert ("Failed to search sibils: "+error)
              }
            })
            .finally(() => this.in_progress = false)
      },
      searchByIds(ids){
        if(this.user_query.tab_selected_name == this.collection_name){
          this.updateTab(this.tab)
          this.condition = true
        }
        this.total = null
        this.in_progress = true
        this.updateSearchResults({'collection': this.user_query.collection, 'results': []})
        var url = this.urls['search']
        var final_query = {}
        final_query['query'] = {}
        final_query['query']['ids'] = {}
        final_query['query']['ids']['values'] = ids
        final_query["_source"] = this.fields[this.user_query.collection].split(",")
        this.cancel();
        let axiosSource = axios.CancelToken.source();
        this.request = { cancel: axiosSource.cancel };
        let body = {'jq': JSON.stringify(final_query), 'col': this.user_query.collection, 'n': ids.length}
        axios
        .post(url, body, {
          cancelToken: axiosSource.token,
        })
            .then(response => {
              if ('success' in response.data && response.data.success == false){
                this.error = response.data.error
                this.total = 0
              }
              else {
                this.error = null
                var documents = response.data.elastic_output.hits.hits
                documents = this.processDate(documents)
                documents = this.processFacets(documents)
                this.total = documents.length
                if (this.total == 1){
                   if("title" in documents[0]._source){
                    window.document.title = documents[0]._source.title
                   }
                  else if("treatment_title" in documents[0]._source){
                    window.document.title = documents[0]._source.treatment_title
                  }
                  else {
                    window.document.title = ids
                  }
                }
                else {
                  window.document.title = ids
                }                
                this.updateSearchResults({'collection': this.user_query.collection, 'results': documents})
                this.selectTab()
              }
            })
            .catch(error => {
              if(error != "Cancel"){
                alert ("Failed to search sibils: "+error)
              }
            })
            .finally(() => this.in_progress = false)
      },
      celltriage(){
        if(this.user_query.tab_selected_name == this.collection_name){
          this.updateTab(this.tab)
          this.condition = true
        }
        this.total = null
        this.in_progress = true
        this.updateSearchResults({'collection': this.collection_name, 'results': []})
        var file = this.urls.celltriage + "results/" + this.user_query.view + "/" +this.user_query.date_min+"_"+this.user_query.date_max+".json"
        
        //var documents = []
        this.cancel();
        let axiosSource = axios.CancelToken.source();
        this.request = { cancel: axiosSource.cancel };
        axios
        .get(file, {
          cancelToken: axiosSource.token,
        })
           .then(response => {
              this.error = null
                var documents = response.data
                documents = this.processDate(documents)
                documents = this.processFacets(documents)
                this.total = documents.length
                this.updateSearchResults({'collection': this.collection_name, 'results': documents})
                this.selectTab()
                this.in_progress = false              
            })
            .catch(error => {
              this.in_progress = false  
              if(error != "Cancel"){
                alert ("Failed to search sibils: "+error)
              }
            })
      },
      cancel() {
        this.updateSearchResults({'collection': this.user_query.collection, 'results': []})
        if (this.request) this.request.cancel();
      },
      selectTab() {
        if (this.user_query.tab_selected_name == null){
          if (this.user_query.tab_selected == this.tab && this.search_results[this.collection_name].length == 0){
              this.updateTab(0)
              this.condition = false
          }
          else if ((this.user_query.tab_selected == 0 && this.search_results[this.collection_name].length > 0) || this.user_query.tab_selected == this.tab){
              this.updateTab(this.tab)
              this.condition = true
          }
          else {
            this.condition = false
          }
        }
      },
      onTabChange(){
          this.$matomo?.trackEvent("Display collection search", this.collection_name);
          this.updateTabName(this.collection_name)
          var newUrl = new URL(window.location.href);
          newUrl.searchParams.set('tab', this.collection_name);
          window.history.replaceState({}, document.title, newUrl.toString());
      },
      processDate(documents){

        if(this.user_query.collection =="pmc" || this.user_query.collection =="suppdata"){
          for (var i=0; i<documents.length; i++){
            var date = null
            documents[i][this.doc_source]['publication_date'] = documents[i][this.doc_source]['publication_date'].replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1")
            if ("pmc_release_date" in documents[i]._source && documents[i][this.doc_source]['pmc_release_date'] != null){
              documents[i][this.doc_source]['pmc_release_date'] = documents[i][this.doc_source]['pmc_release_date'].replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1")
              date = documents[i][this.doc_source]['pmc_release_date']
            }
            else {
              date = documents[i][this.doc_source]['publication_date']
            }
            documents[i][this.doc_source]['pmc_date'] = date
          }
        }
        return documents

      },
      processFacets(documents){

        
        for (var i=0; i<documents.length; i++){
          var processed_facets = {}
                        
          // Process one facet
          for (let f=0; f<this.filters.documents.facets.length; f++){
            
            let facet_name = this.filters.documents.facets[f].short
            var entities = []
            if (facet_name in documents[i][this.doc_source] && documents[i][this.doc_source][facet_name] != null){
              var values = []
              if (typeof documents[i][this.doc_source][facet_name] === 'string'){
                if (documents[i][this.doc_source][facet_name].includes("|")){
                  values = documents[i][this.doc_source][facet_name].split("|");
                }
                else {
                  values = [documents[i][this.doc_source][facet_name]]
                }
              }
              else {
                values = documents[i][this.doc_source][facet_name]
              }
              for (let v=0; v<values.length; v++){
                
                if(facet_name == "language"){
                  if (values[v] != ""){
                    entities.push(values[v].toLowerCase())
                  }
                  else {
                    entities.push("unknown")
                  }
                }
                else{
                  if (values[v] != ""){
                    entities.push(values[v])
                  }
                }
              }
            }

            else {
              var annotations = []

              if (this.$route.name === "CellTriagePage"){

                if ("annotations" in documents[i]){
                  annotations = documents[i].annotations
                }
                for (let a=0; a<annotations.length; a++){
                    let elements = annotations[a]
                    let term = elements.preferred_term
                    let type = elements.concept_source    
                    type = type.replaceAll("-", "_")
                    if(this.$route.name === "CellTriagePage"){
                      if (/[a-zA-Z]/.test(elements.concept_form)){
                        if (type.toLowerCase() == facet_name.toLowerCase() && term != ""){
                          entities.push(term)
                        }
                      }
                    }
                    else {
                      if (type.toLowerCase() == facet_name.toLowerCase() && term != ""){
                        entities.push(term)
                      }
                    }
                }

              }
              else {
                if ("annotations_material" in documents[i]._source){
                  annotations = documents[i]._source.annotations_material.split("||")
                }
                for (let a=0; a<annotations.length; a++){
                    let elements = annotations[a].split("|");
                    let term = elements[2]
                    let type = elements[0]    
                    type = type.replaceAll("-", "_")
                    if (type.toLowerCase() == facet_name.toLowerCase() && term != ""){
                      entities.push(term)
                    }
                }

              }
              
            }

            // Store values without dupplicated
            processed_facets[facet_name] = [...new Set(entities)];

          }

          documents[i].processed_facets = processed_facets

        }

        return documents

      },
    },
  }

</script>

<style scoped lang="scss">

.progress-container {
    text-align: center;
    width: 100%;
    padding: 50px;
  }

  .content-container {
      display: flex;
  }

  .facets-container {
      float: left;
      margin-right: 20px;
  }

  .full-container {
      width: 100%;
      margin: 0 auto;
  }

    .title-container{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title-span {
      padding-right: 10px;
    }

    .error-container{
      background-color: lightcoral;
      padding: 10px;
      color: #fff;
    }

 
</style>
