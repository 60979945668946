<template>
    <div class="dropdown-container" @mouseover="openDropdown" @mouseleave="closeDropdown">
      <a href="#"><span class="link">Links</span></a>
      <div class="dropdown" v-show="dropdownVisible" @mouseover="openDropdown" @mouseleave="closeDropdown">
        <div v-if="int_links">
          <label>Internal links</label>
          <ul>
            <li v-for="link in int_links" :key="link.url"> <a  :href="link.url" target="_blank" >{{ link.name }}</a></li>
          </ul>
        </div>
        <div v-if="ext_links">
          <label>External links</label>
          <ul>
            <li v-for="link in ext_links" :key="link.url"> <a  :href="link.url" target="_blank" >{{ link.name }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dropdownVisible: false,
      };
    },
    props: {
        int_links: {
            type: Array,
        },
        ext_links: {
            type: Array,
        },
    },
    methods: {
      toggleDropdown() {
        this.dropdownVisible = !this.dropdownVisible;
      },
      openDropdown() {
        this.dropdownVisible = true;
      },
      closeDropdown() {
        this.dropdownVisible = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .dropdown-container {
    position: relative;
    display: inline-block;
    text-align: left;
  }
  
  .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
    z-index: 1000;
    padding: 10px 0 0 10px;
  }
  label {
    font-weight: bold;
  }
  
  .dropdown-container:hover .dropdown {
    display: block;
    max-height: 250px; 
  }

  .link {
        color: #2c3e50;
    }

    .link:hover {
        color: #2c3e50;
    }
  </style>
  