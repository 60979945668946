<template>

    <div class="example-container">

      <h3>Search examples</h3>
      <a v-for="(example, index) in examples.search_mode" :key="index+'example_search'" @click="updateQueryAndLog(example)">{{ example.query }}<br/></a>
      <br/>
      <h3>Question-answering examples</h3>
      <p class="info">The question-answering mode is limited to MEDLINE and PLAZI collections.</p>
      <a v-for="(example, index) in examples.qa_mode" :key="index+'example_qa'" @click="updateQueryAndLog(example)">{{ example.query }}<br/></a>
      <br/>
      <h3>Examples for other services</h3>
      <a v-for="(example, index) in examples.other_mode" :key="index+'example_other'" target="_blank" :href="example.url">{{ example.query }}<br/></a>

    </div>

</template>


<script>
import { mapState, mapActions } from 'vuex'

    export default {
      name: 'QueryExample',
      components: {
      },
      data() {
        return {

        };
      },
      computed: {
        ...mapState(['examples', 'user_query']),
      },
      methods:{
        ...mapActions(['updateQueryOriginal']),
        updateQueryAndLog(example){
          this.$matomo?.trackEvent("Select example", example.query);
          this.updateQueryOriginal(example.translated)
          if(example.date != null){
            this.$matomo?.trackEvent("Options date range", example.date);
            const now = new Date();
            var minDate = new Date();
            this.user_query.date_max = now
            if (example.date == "year"){
              minDate = minDate.setFullYear(minDate.getFullYear() - 1);
            }
            else if (example.date == "month"){
              minDate = minDate.setMonth(minDate.getMonth() - 1);      
            }
            else if (example.date == "week"){
              minDate = minDate.setDate(minDate.getDate() - 7);
            }
            let final_date = new Date(minDate)
             this.user_query.date_min = final_date
          }
          else{
            this.user_query.date_max = null
            this.user_query.date_min = null
          }
        }
      },
    }

</script>


<style scoped lang="scss">

    .example-container {
        margin: 20px 0;
        text-align: left;
    }

</style>
