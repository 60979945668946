let exclusion = require('../assets/js/exclusion.js');

export default {

  filterThis(settings, document){

    var status = true;
    var user_selection = settings.user_selection
    var filters = settings.filters
    var dates = settings.dates

    // Define facets allowing multiple values
    var multi = []
    for (const filter_nb in filters){
        if (settings.filters[filter_nb].multi === true){
            multi.push(settings.filters[filter_nb].short)
        }
    }

    // Filter documents
    for (const key in user_selection) {

        // If the document is not already rejected
        if (status){

            // If facet with multiple values
            if (multi.includes(key)){

                let list = user_selection[key]

                // If there is at least one value selected
                if (list.length > 0){

                    // The document is filtered out except if one value match
                    thisStatus = false;

                    for (let i=0; i<list.length; i++){

                        for (let f=0; f<document.processed_facets[key].length; f++){
                            if (document.processed_facets[key][f] == list[i]){
                                thisStatus = true;
                                break;
                            }
                        }

                    }

                    // If no value matched, reject the document
                    if (!thisStatus){
                        status = false;
                        break;
                    }

                }

            }

            // If facet with one single value
            else {

                var list = user_selection[key]

                for (let i=0; i<list.length; i++){

                    // The document is filtered out except if all value match
                    var thisStatus = false;

                    for (let f=0; f<document.processed_facets[key].length; f++){
                        if (document.processed_facets[key][f] == list[i]){
                            thisStatus = true;
                        }
                    }

                    if (!thisStatus){
                        status = false
                        break;
                    }
                }

            }

        }

    }

    // Filter date
    if (dates.min != null || dates.max != null ){
        var min = dates.min
        var max = dates.max
        var article_date = null
        if ('entrez_date' in document._source){
            article_date = new Date(document._source.entrez_date)
        }
        else if ('pmc_release_date' in document._source){
            article_date = new Date(document._source.pmc_release_date)
        }
        else if ('publication_date' in document._source){
            article_date = new Date(document._source.publication_date)
        }
        if (article_date != null){
            if ((max != null && article_date > max) || (min != null && article_date < min)){
                return false
            }
        }
    }

     return status;

  },

  highlight(inputText, words){

    exclusion.push("or")
    exclusion.push("and")

    words = words.filter(function(val) {
        return exclusion.indexOf(val) == -1;
      });

    for (var w=0; w<words.length; w++){
       words[w] =  words[w].replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }

    words = [...new Set(words)]

    inputText = inputText.replaceAll("&", "&amp;")
    inputText = inputText.replaceAll("<", "&lt;")
    inputText = inputText.replaceAll(">", "&lgt;")
    
      if (words.length > 0){
        var re = new RegExp("\\b("+words.join("|")+")s?\\b", "gi");

        inputText = inputText.replaceAll(re, function replace(match) { 
            return '<span style="color:#CC0000">' + match + '</span>';
        });
      }

    return inputText
  },
}
