<template>

  <div :class="cssFullText">

    <pmca-element :id="buildId"/>
  </div>

</template>

<script>
  import { mapState, mapActions } from 'vuex'

    export default {
      name: 'FulltextViewer',
      props: {
        id: {
            type: String,
            required: true
        },
        collection: {
            type: String,
            default: "pmc"
        },
        terms_to_highlight: {
          type: Array
        },
      },
      computed: {
        ...mapState(['user_query', 'pmca_parameters']),
            buildId(){
              if (this.user_query.pmcviewer != null){
                return "full_page_viewer"
              } 
              else {
                return "PMCA_"+"_"+this.id.replaceAll("/", "").replaceAll(".", "")
              }

            },
            cssFullText(){
              if (this.user_query.pmcviewer != null){
                return "full-height"
              }
              else {
                return "limited-height"
              }
            }
      },
      methods: {
        ...mapActions(['updateQueryPmcviewer']),
      handlePopState() {
        this.updateQueryPmcviewer(null)
      },
    },
      mounted() {

        document.querySelector("#"+this.buildId).reset();
        var pmca = document.querySelector("#"+this.buildId)
        //var myoptions = {service:"httpscandyv33", col: this.collection};
        var myoptions = { ...this.pmca_parameters, col: this.collection, showbacksections: true};
        if (this.$route.name === "CellTriagePage"){
          //myoptions = { service : "httpscandyv33" , cello: true,  col: this.collection };
          myoptions = { ...this.pmca_parameters, cello: true,  col: this.collection };
          pmca.defaultForAllFilters = false;
          pmca.stateForFilters = {names:["cellosaurus"], state:true};
        }

        else {
            // build extra annotations for variant
            var highlighted_src = " Query terms";
          var query_terms = [];
          pmca.extraAnnotations = query_terms;
          for (var t=0; t<this.terms_to_highlight.length; t++){
            var term = this.terms_to_highlight[t];
            var annotTerm = {concept_form:term, concept_id:"",concept_source: highlighted_src, preferred_term: term, special_id:"", type: "query", version: "",  };
            query_terms.push(annotTerm);
          }
  
          // add extra annotations, setup filter states and display pmcid
          pmca.extraAnnotations = query_terms;
          pmca.defaultForAllFilters = false;
          pmca.stateForFilters = {names:[highlighted_src], state:true};
        }
        
        // add extra annotations, setup filter states and display id
        pmca.fillViewerWithIdAndOptions(this.id, myoptions);

      },
      created() {
    window.addEventListener('popstate', this.handlePopState);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopState);
  }

  }

</script>

<style scoped>

.limited-height {
    margin-top: 10px;
    text-align: left;
    height: 1000px;
}
.full-height {
  margin-top: 10px;
  text-align: left;
}

</style>