<template>

    <div id="app" :style="cssVars" class="d-flex flex-column min-vh-100">

      <div id="nav" v-if="this.$route.name !== 'CellTriagePage'">
        <a href="/">Search</a> |
        <a href="/collections/">Collections</a> |
          <a href="https://sibils.org/api/biodiversitypmc/">API</a> |
          <a href="https://sibils.org/biodiversitypmc/">About</a> |
          <router-link to="/privacy">Privacy</router-link>
      </div>

      <router-view/>

      <div class="row mt-auto">

        <div class="row">
          <div class="issue-container">
            <a :href="get_report_link" target="_blank">Bug report</a> | <a :href="get_feature_request_link" target="_blank">Feature request</a>            
          </div>
        </div>

        <div class="logo row mt-auto">

          <div class="col-1">
                <img src="./assets/images/logo_sib.png"/>
          </div>
          <div class="col-1">
                <img src="./assets/images/logo_elixir.png"/>
          </div>
          <div class="col-1">
                <img src="./assets/images/logo_heg.png"/>
          </div>
          <div class="col-1">
                <img src="./assets/images/logo_swissuni.png"/>   
          </div>
          <div class="col-1">
                <img src="./assets/images/logo_hesso.jpg"/>
          </div>
          <div class="col-1">
                <img src="./assets/images/logo_sibils.png"/>
          </div>

        </div>

      </div>


    </div>

</template>

<script>
import { mapState } from 'vuex'

    export default {
      computed: {
            ...mapState(['theme_color', 'urls', 'pmca_parameters']),
            cssVars () {
                return{
                    '--color-main': this.theme_color.main,
                }
            },
    baseURL() {
      return process.env.VUE_APP_URL_PUBLIC_PATH;
    },
        get_report_link(){
          var url = window.location.href
          var params = (new URL(document.location)).searchParams;
          return "https://github.com/sibils/community/issues/new?template=bugreport.yaml&labels=bug,biodiversityPMC&service=Biodiversity PMC&url="+encodeURI(url+params)
        },
        get_feature_request_link(){
          return "https://github.com/sibils/community/issues/new?template=featurerequest.yaml&labels=enhancement,biodiversityPMC&service=Biodiversity PMC"
        },
      },
      mounted() {
        let script1 = document.createElement('script')
        script1.setAttribute('src', this.urls["pmca"] + '/src/proxy-data-access.js')
        script1.setAttribute('crossorigin', 'anonymous')
        document.head.appendChild(script1)      
        let script2 = document.createElement('script')
        script2.setAttribute('src', this.urls["pmca"] + '/src/proxy-data-parser.js')
        script2.setAttribute('crossorigin', 'anonymous')
        document.head.appendChild(script2)
        let script3 = document.createElement('script')
        script3.setAttribute('src', this.urls["pmca"] + '/node_modules/@webcomponents/webcomponentsjs/webcomponents-loader.js')
        document.head.appendChild(script3)
        let script4 = document.createElement('script')
        script4.setAttribute('src', this.urls["pmca"] + '/src/pmca-element.js')
        script4.setAttribute('type', 'module')
        script4.setAttribute('crossorigin', 'anonymous')
        document.head.appendChild(script4)      
      },
      watch: {
        '$route' (to) {
          if(Object.keys(to.query).length == 0){
            document.title = "SIBiLS"
          }
      }
    },
    }

</script>


<style lang="scss">

   #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      font-size: 0.8em;

      .banner-container {
        width: 99%;
        margin: 0 auto;
      }

      .component-container {
        width: 100%;
        margin: 0 auto;
      }

      .query-container {
        padding: 20px;
        background-color: #F2F2F2;
        border-radius: 10px;
      }

      a {
        font-weight: bold;
        text-decoration: none;
        color: #CC0000;
      }
      a:hover {
        text-decoration: underline;
      }

      h1 {
        font-size: 1.8em;
        padding: 20px 0;
        margin: 0;
      }
      h2 {
        font-size: 1.2em;
        padding: 15px 0;
        margin: 0;
      }
      h3 {
        font-size: 1.1em;
        padding: 10px 0;
        margin: 0;
      }

      .info {
        font-style: italic;
        font-size: 0.8em;
      }

      .required {
        color: red;
      }

      .nav-pills .tab-title .nav-link:not(.active) {
        background-color: #eee !important;
        border: 1px solid #ddd;
        border-radius: 0px;
        color: #000000;
        font-weight: normal;
      }

      .nav-pills .tab-title .nav-link {
        background-color: var(--color-main) !important;
        color: #fff;
        text-decoration: none;
        font-weight: normal;
        border-radius: 0px;
        border: 1px solid var(--color-main);
      }

      .page-item .page-link {
        color: #2c3e50;
      }

      .page-item .page-link:hover {
        color: #2c3e50;
      }
      .page-item.active .page-link {
        color: #fff !important;
        background: var(--color-main) !important;
        border: 0px solid var(--color-main);
      }

      .page-item.active {
        color: #fff !important;
        background: var(--color-main) !important;
        border: 1px solid var(--color-main);
      }

      .logo {
        width: 100%;
        margin-top: 50px;
        border-top: 1px solid var(--color-main);
        border-bottom: 1px solid var(--color-main);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 50px;
      }
      .logo img {
        width: 90%
      }

      .issue-container {
        padding-bottom: 20px;
      }

      .separator h2 {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 1em;
        }

        .separator h2::before,
        .separator h2::after {
          content: "";
          display: block;
          flex-grow: 1;
          height: 1px;
          background: #ccc;
        }

        .separator h2 span {
          padding: 0 2em;
        }
        .separator {
        margin-bottom: 20px;
        }

        

    }

    #nav {

      padding: 30px;

      a {
        font-weight: bold;
        color: #2c3e50;
        text-decoration: none;

        &.router-link-exact-active {
          color: #D00000;
        }
      }
      a:hover {
        text-decoration: underline;
      }

    }

</style>
