<template>

  <div class="container-fluid">
    <BannerComponent />

    <div class="row" v-show="user_query.mode!='id' && user_query.pmcviewer == null">
        <QuerySearchBox @searchSibils=searchSibils />
    </div>

    <div class="row">
        <ResultsBox ref="resultsBox"/>
    </div>

    <div class="row" v-show="user_query.pmcviewer == null">
        <ExportBox v-if="search_results.medline.length > 0 || search_results.plazi.length > 0 || search_results.pmc.length > 0  || search_results.suppdata.length > 0  || qa_results.medline.length > 0 || qa_results.plazi.length > 0 "/>
    </div>

  </div>

</template>

<script>

import BannerComponent from '@/components/BannerComponent.vue'
import QuerySearchBox from '@/components/QuerySearchBox.vue'
import ResultsBox from '@/components/ResultsBox.vue'
import ExportBox from '@/components/ExportBox.vue'
import { mapState, mapActions } from 'vuex'


export default {
  name: 'Home',
  components: {
    BannerComponent,
    QuerySearchBox,
    ResultsBox,
    ExportBox
  },
  data() {
        return {
            showResults: false,
            client_ip: null
        }
  },
  computed: {
        ...mapState(['user_query', 'user_selection', 'theme_color', 'qa_results', 'search_results', 'logs']),
  },
  methods:{
    ...mapActions(['updateQueryOriginal', 'updateSearchSize', 'updateQaSize', 'updateModeExec', 'updateQuerySearch', 'updateQueryNormalization', 'updateQueryTransformation', 'updateQueryDateMin', 'updateQueryDateMax', 'updateMode', 'updateTabName', 'updateProviderSearch', 'updateProviderName']),
    searchSibils(){
      this.updateQuerySearch(this.user_query.original)
      this.updateModeExec(this.user_query.mode)
      var options = {}
      if (this.user_query.search != ""){
        if(this.user_query.mode_exec == 'id'){
          options['id'] = this.user_query.search.replace("ID:", "")
        }
        else {
          options['query'] = this.user_query.search
        }
      }
      if (this.user_query.mode_exec == 'search' && this.user_query.search_size != 250){
        options['search_size'] = this.user_query.search_size
      }
      if (this.user_query.mode_exec == 'qa' && this.user_query.qa_size != 5){
        options['qa_size'] = this.user_query.qa_size
      }
      if (this.user_query.transformation == false){
        options['transformation'] = this.user_query.transformation
      }
      if (this.user_query.normalization == false){
        options['normalization'] = this.user_query.normalization
      }
      if (this.user_query.tab_selected_name != null){
        options['tab'] = this.user_query.tab_selected_name
      }
      if (this.user_query.date_min != null){
        options['from'] = this.user_query.date_min.getFullYear()+"-"+(this.user_query.date_min.getMonth()+1)+"-"+this.user_query.date_min.getDate()
      }
      if (this.user_query.date_max != null){
        options['to'] = this.user_query.date_max.getFullYear()+"-"+(this.user_query.date_max.getMonth()+1)+"-"+this.user_query.date_max.getDate()
      }
      if (this.user_query.provider_name != null){
        options['provider'] = this.user_query.provider_name
      }

      this.$router.push({ name: 'HomePage', query: options }).catch(()=>{});
      this.$refs.resultsBox.search()
      //this.log(options)
    },
    run(){
      if(this.user_query.original.length > 0){
        if (this.user_query.transformed != "") {
          this.searchSibils()
        } else {
          setTimeout(this.run, 10); // try again in 300 milliseconds
        }
      }
    },

    // log(options){
    //   if (this.client_ip == null){
    //       fetch('https://api.ipify.org?format=json')
    //       .then(x => x.json())
    //       .then(({ ip }) => {
    //         alert(ip)
    //           this.client_ip = ip
    //           this.storeIp(options)
    //       });
    //   }
    // },
    // storeIp(options){
    //   const { Console } = require("console");
    //   const fs = require('fs');

    //   // make a new logger
    //   const myLogger = new Console({
    //     stdout: fs.createWriteStream(this.logs.dev),
    //   });

    //   myLogger.log(JSON.stringify(options));
    //   alert(JSON.stringify(options))


    //   //var file = window.open(this.logs.dev);
    //   //file.print(JSON.stringify(options))   
    // }

  },
  beforeMount() {
    if ('id' in this.$route.query && this.$route.query.id.length > 0){
        var id = this.$route.query.id
        this.updateQuerySearch("ID:"+id)
        this.updateQueryOriginal("ID:"+id)
        this.updateMode('id')
    }
    if ('query' in this.$route.query && this.$route.query.query.length > 0){
        var query = this.$route.query.query
        this.updateQuerySearch(query)
        this.updateQueryOriginal(query)
        let text = query
        text = text.trim()
        if (text.endsWith('?')){
          this.updateMode('qa')
        }
        else {
          this.updateMode('search')
        }
    }
    if ('search_size' in this.$route.query && this.$route.query.search_size.length > 0){
      var size = this.$route.query.search_size
      this.updateQuerySize(size)
    }
    if ('qa_size' in this.$route.query && this.$route.query.qa_size.length > 0){
      var qa_size = this.$route.query.qa_size
      this.updateQaSize(qa_size)
    }
    if ('normalization' in this.$route.query && this.$route.query.normalization.length > 0){
      var normalization = this.$route.query.normalization
      this.updateQueryNormalization(normalization.toLowerCase() === "true")
    }
    if ('transformation' in this.$route.query && this.$route.query.transformation.length > 0){
      var transformation = this.$route.query.transformation
      this.updateQueryTransformation(transformation.toLowerCase() === "true")
    }
    if ('from' in this.$route.query && this.$route.query.from.length > 0){
      var from = new Date(this.$route.query.from)
      this.updateQueryDateMin(from)
    }
    if ('to' in this.$route.query && this.$route.query.to.length > 0){
      var to = new Date(this.$route.query.to)
      this.updateQueryDateMax(to)
    }
    if ('tab' in this.$route.query && this.$route.query.tab.length > 0){
      var tab = this.$route.query.tab
      this.updateTabName(tab)
    }
    if ('provider' in this.$route.query && this.$route.query.provider.length > 0){
      var provider = this.$route.query.provider
      if (provider == "blr"){
        this.updateProviderSearch(["plazi"])
      }
      this.updateProviderName(provider)
    }
  },
  mounted(){
    this.run()
  },
}

</script>

<style scoped lang="scss">

    .container-fluid{
        padding-right: 0px;
        padding-left: 0px;
        margin-right: auto;
        margin-left: auto;
    }

    .row {
        padding: 50px;
        margin-left: 0;
        margin-right: 0;
    }

</style>