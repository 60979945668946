<template>

  <div class="advanced-container">

    <PanelHideShow :expanded_init="false" size="small" log_name="Advanced query">

      <template v-slot:title>Advanced query</template>
      <template v-slot:content>
        
        <div v-if="in_progress" class="progress-container">
            <PulseLoader :color="theme_color.main"/>
        </div>

        <div v-else>
          <p class="error" v-if="alert_transformation">
           The query transformation service is currently experiencing an issue and could not be executed successfully. 
           However, you can proceed with your search without the transformation service. Feel free to retry the transformation service later. Thanks for your understanding!
         </p>
         <p class="error" v-if="alert_annotation">
           The query annotation service is currently experiencing an issue and could not be executed successfully. 
           However, you can proceed with your search without the annotation service. Feel free to retry the annotation service later. Thanks for your understanding!
         </p>
          <div class="query-part">
            <div class="row" v-if="user_query.mode == 'qa'">
              <div class="col-2"><label>Words to be ignored for retrieval:</label></div>
              <div class="col-10">
                <table v-if="user_query.terms_status.length > 0">
                  <tr><th>Term</th><th>Selected</th></tr>
                    <tr v-for="(term_status, index) in user_query.terms_status" :key="'termstatus_'+index">
                      <td>{{ term_status.term }}</td>
                      <td><input type="checkbox" v-model="term_status.status" @click="updateSelectionTermStatus(index, term_status.status)"></td>
                    </tr>
                </table>
                <span v-else>No concept found</span>
              </div>
            </div>
            <div class="row" v-if="user_query.normalization">
              <div class="col-2"><label>Your query is normalized as:</label></div>
              <div class="col-10">
                <table v-if="user_query.normalized_concepts.length > 0">
                  <tr><th>Term</th><th>Preferred term</th><th>Concept ID</th><th>Type</th><th>Terminology</th><th>Selected</th></tr>
                  <template v-for="(annotations, index) in user_query.normalized_concepts">
                    <tr v-for="(annotation, index2) in annotations" :key="'annotations_'+index+'_'+index2">
                      <td>{{ annotation.concept_form }}</td>
                      <td>{{ annotation.preferred_term }}</td>
                      <td>{{ annotation.concept_id }}</td>
                      <td>{{ annotation.type.charAt(0).toUpperCase() + annotation.type.slice(1) }}</td>
                      <td>
                        <a :href="links[annotation.concept_source]" target="_blank" v-if="annotation.concept_source in links">
                          {{ sources[annotation.concept_source] }} 
                        </a>
                        <span v-else>
                          {{ sources[annotation.concept_source] }} 
                        </span>
                      </td>
                      <td><input type="checkbox" v-model="annotation.checked" @click="updateSelectionAnnotation(index, index2, annotation.checked)"></td>
                    </tr>
                  </template>
                </table>
                <span v-else>No concept found</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2"><label>Your query is tranformed as:</label></div>
              <div class="col-10">
                <span v-html="get_pretty_query" v-if="user_query.transformed != null && user_query.transformed != ''"></span>
                <span v-if="user_query.transformed == null" class="error">Invalid syntax</span>
              </div>
            </div>
            <div v-if="in_progress_partial" class="progress-container">
                <PulseLoader :color="theme_color.main"/>
            </div>

          </div>

        </div>


      </template>

    </PanelHideShow>

  </div>

</template>


<script>
import { mapState, mapActions } from 'vuex'
import PanelHideShow from '@/components/PanelHideShow.vue'
import axios from 'axios';
var PulseLoader = require('vue-spinner/src/PulseLoader.vue').default;
let mapping = require('../assets/js/mapping_termin.js');


  export default {
    name: 'QueryAdvanced',
    components: {
      PanelHideShow,
      PulseLoader
    },
    data() {
      return {
        timer: null,
        in_progress: false,
        in_progress_partial: false,
        sources: {},
        links: {},
        alert_annotation: false,
        alert_transformation: false
      };
    },
    computed: {
      ...mapState(['user_query', 'urls', 'theme_color']),
      cssVars () {
        return{
           '--color-main': this.theme_color.main,
        }
      },
      get_pretty_query(){
        return("<pre>"+JSON.stringify(this.user_query.transformed, null, 2)+"</pre>")
      },
      get_terminology(source){
        if (source in this.sources){
          return this.sources[source]
        }
        else{
          return source
        }
      }
    },
    methods:{
      ...mapActions(['updateTransformedQuery', 'updateQueryConceptsNormalized', 'updateQueryTermsStatus']),
      searchWithTimer(query){
          this.in_progress = true
          this.updateTransformedQuery("")
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
              this.processQuery(query)
          }, 1000);
      },
      searchWithTimerCheckList(query){
        this.in_progress_partial = true
          clearTimeout(this.timer);
          this.updateTransformedQuery("")
          this.timer = setTimeout(() => {
              this.processQuery(query)
          }, 1000);
      },
      processQuery(query){
          this.cancel();
          this.updateTransformedQuery("")
          this.alert_transformation = false
          this.alert_annotation = false
            var unselection = []
            for (var c=0; c<this.user_query.normalized_concepts.length; c++){
              var annotations = this.user_query.normalized_concepts[c]
              for (var a=0; a<annotations.length; a++){
                if (annotations[a].checked == false){
                  unselection.push(annotations[a].concept_form+"|"+annotations[a].concept_id+"|"+annotations[a].concept_source)
                }
              }
            }
            var terms_status = []
            for (var s=0; s<this.user_query.terms_status.length; s++){
              terms_status.push(this.user_query.terms_status[s].term+"|"+this.user_query.terms_status[s].status)
            }
            if (query.substring(0, 3) == "ID:"){
              this.updateQueryConceptsNormalized([])
              this.updateQueryTermsStatus([])
              var transformed = {
                        "query": {
                          "multi_match": {
                            "query": query.substring(3),
                            "fields": [
                              "_id",
                              "pmid",
                              "pmcid"
                            ]
                          }
                        }
                      }
              this.updateTransformedQuery(transformed)
              this.in_progress = false
              this.in_progress_partial = false
            }
            else if (query.length > 0 && this.user_query.transformation == true){
              let url = this.urls.querytransformer+"?text_query="+encodeURIComponent(query)+"&transf="+this.user_query.transformation+"&norm="+this.user_query.normalization+"&unselect="+unselection.join("||")+"&terms_status="+terms_status.join("||")
              let axiosSource = axios.CancelToken.source();
              this.request = { cancel: axiosSource.cancel };
              axios
                .get(url, {cancelToken: axiosSource.token})
                .then(response => {
                  this.updateQueryConceptsNormalized(response.data.concepts)
                  this.updateQueryTermsStatus(response.data.terms_status)
                  this.updateTransformedQuery({"query": response.data.query})
                  this.in_progress = false
                  this.in_progress_partial = false
                  if (response.data.annotation_status == false){
                    this.alert_annotation = true
                  }
                })
                .catch(error => {
                  if (error != "Cancel"){
                    this.skipTransformation(query)
                    this.alert_transformation=true
                  }
                })   
            }
            else if (query.length > 0 && this.user_query.transformation == false) {
              this.skipTransformation(query)
            }
            else {
                this.updateQueryConceptsNormalized([])
                this.updateQueryTermsStatus([])
                this.updateTransformedQuery('')
                this.in_progress = false
                this.in_progress_partial = false
            }
     
      },
      skipTransformation(query){
        this.updateQueryConceptsNormalized([])
        this.updateQueryTermsStatus([])
        var transformed = {
                        "query": {
                          "multi_match": {
                            "query": query,
                            "fields": [
                              "title",
                              "abstract",
                              "text",
                              "full_text",
                              "chemicals",
                              "keywords",
                              "mesh_terms",
                              "sup_mesh_terms",
                              "figures_captions",
                              "article-title",
                              "text",
                              "treatment_title",
                              "_id",
                              "pmid",
                              "pmcid"
                            ]
                          }
                        }
                      }
        this.updateTransformedQuery(transformed)
        this.in_progress = false
        this.in_progress_partial = false
      },
      cancel() {
        this.updateTransformedQuery("")
        if (this.request) {
          this.request.cancel();
        }
      },
      updateSelectionAnnotation(index, index2, status){
        this.cancel()
          var concepts_list = this.user_query.normalized_concepts
          this.$matomo?.trackEvent("Options annotation selection", concepts_list[index][index2]["concept_form"]+";"+concepts_list[index][index2]["concept_source"]+";"+concepts_list[index][index2]["concept_id"]+";"+!status);
          if (status == true){
            concepts_list[index][index2].checked = false
          }
          else {
            concepts_list[index][index2].checked = true
          }
          this.updateQueryConceptsNormalized(concepts_list)
          this.searchWithTimerCheckList(this.user_query.original)
          //this.processQuery(this.user_query.original)
      },
      updateSelectionTermStatus(index, status){
        this.cancel()
          var concepts_list = this.user_query.terms_status
          this.$matomo?.trackEvent("Options annotation selection", concepts_list[index]["term"]+";"+!status);
          if (status == true){
            concepts_list[index].status = false
          }
          else {
            concepts_list[index].status = true
          }
          this.updateQueryTermsStatus(concepts_list)
          this.searchWithTimerCheckList(this.user_query.original)
          //this.processQuery(this.user_query.original)
      },
      getLabel(element, type){
        var value = ""
        if (type != "Keywords"){
          value = element.preferred_term+" ["+element.concept_id+"]"
        }
        else {
          value = element
        }  
        return value
      },
    splitQuery(query){
            // Split the query
            query = query.replaceAll("and", "AND")
            query = query.replaceAll("or", "OR")

            var re = /\s*(([\\(\\)]|OR|AND)+\s*)+/gi;
            var result;
            while((result = re.exec(query)) !== null) {
                query = query.replace(result[0], "///")
            }
            
            var elements = query.split("///")
            var built_query = []
            for (var i=0; i<elements.length; i++){
              if (elements[i].length > 0){
                built_query.push(elements[i])
              }
            }
            return built_query
        },
        loadSources(){
          for (var i=0; i<mapping.length; i++){
            var elements = mapping[i].split("|")
            this.sources[elements[7].trim()]= elements[1].trim()
            var link = elements[6].trim()
            link = link.replace(/^\[.*?\]/, "")
            link = link.replace("(", "")
            link = link.replace(")", "")
            if (link != "") {
              this.links[elements[7].trim()]= link.trim()
            }
          }
        }
    },
    watch: {
        "user_query.original": function () {
          this.searchWithTimer(this.user_query.original)
        }, 
        "user_query.normalization": function () {
          this.in_progress = true
          this.processQuery(this.user_query.original)
        },  
        "user_query.transformation": function () {
          this.in_progress = true
          this.processQuery(this.user_query.original)
        },  
    },
    mounted() {
      this.processQuery(this.user_query.original)
      this.loadSources()
    }

  }

</script>


<style scoped lang="scss">

.mini{
  width: 15px;
  margin-left: 5px;
}

.advanced-container {
background-color: rgb(235, 235, 235);
border-radius: 20px;
padding: 0 20px 10px 20px;
margin: 0 10px;
text-align: left;
font-size: 0.8em
}

.type-container {
margin: 10px 0;
}
label {
width: 120px;
font-weight: bold;
}


active-options{
text-align: left;
margin-bottom: 10px;
}

  .option-name {
    background-color: #CCC;
    border-radius: 10px;
    padding: 2px 5px;
    margin-right: 5px;
  }

  .option-remove {
    color: #FFF;
    border-left: 1px solid #FFF;
    margin-left: 5px;
  }

  .option-remove:hover {
    cursor:pointer;
    color: var(--color);
  }

  .error {
    color: red;
  }

  label {
    width: 250px;
  }

  table {
    min-width: 400px;
  }
  td, th{
    border: 1px solid #000;
    padding: 2px 5px;
  }


  .row {
    margin-bottom: 20px;
  }
</style>

