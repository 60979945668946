<template>

    <div>
    
            <p>
                {{ processed_documents_list.length }}
                    <span v-if="processed_documents_list.length <= 1">{{ document_name }}</span>
                    <span v-if="processed_documents_list.length > 1">{{ document_name }}s</span>
                     with your filters
                (Total: {{ documents.length }}
                    <span v-if="documents.length <= 1">{{ document_name }}</span>
                    <span v-if="documents.length > 1">{{ document_name }}s</span>)
            </p>

            <FiltersSelection :processed_size="processed_documents_list.length" :total_size="documents.length" entity_name="study" :user_selection="user_selection.documents" :filters="filters.documents" :updateFacet="updateDocumentsFacet" :updateDates="updateDocumentsDates"/>

            <div v-if="processed_documents_list.length > 0">

                <ResultsSearchDocument v-for="(document, index) in processed_documents_list.slice(item_min, item_max)" :key="document._id" :document_init="document" :rank="index"  :collection_name="collection_name" :collection_title="collection_title" :page="current_page" :per_page="per_page"/>

                <br/>

                <div class="page-box">
                    <v-pagination v-model="current_page" :page-count="page_total" :classes="bootstrapPaginationClasses" :labels="paginationAnchorTexts"></v-pagination>
                </div>

                <p v-if="!show_size && processed_documents_list.length > per_page"><a @click="showAll()">Show all {{ document_name }}s</a></p>
                <p v-if="show_size && processed_documents_list.length > per_page_init"><a @click="showSome()">Show {{ per_page_init }} {{ document_name }}s per page</a></p>

                <br/>

            </div>



    </div>

</template>

<script>

import { mapState, mapActions } from 'vuex'
import FiltersSelection from '@/components/FiltersSelection.vue'
import ResultsSearchDocument from '@/components/ResultsSearchDocument.vue'
import vPagination from 'vue-plain-pagination'
import shared from '@/components/shared.js'

export default {
name: 'ResultsSearchDocuments',
components: {
FiltersSelection,
ResultsSearchDocument,
vPagination,
},
props: {
        documents: {
            type: Array,
            required: true
        },
        collection_name: {
            type: String,
            required: true
        },
        collection_title: {
            type: String,
            required: true
        },
        document_name : {
            type: String,
            default: "document"
        }
      },
data() {
return {
    in_progress: true,
    current_page: 1,
    per_page_init: 20,
    per_page: 20,
    show_size: false,
    bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
    },
    paginationAnchorTexts: {
        first: '<<',
        prev: '<',
        next: '>',
        last: '>>'
    }
};
},
computed: {
...mapState(['urls', 'user_selection', 'filters', 'doc_source' ]),
page_total(){
   return Math.ceil(this.processed_documents_list.length/this.per_page)
},
item_min(){
    return ((this.current_page-1)*this.per_page)
},
item_max(){
    return ((this.current_page*this.per_page))
},
processed_documents_list(){

    var filtered = this.documents

   // Sort documents
   if (this.user_selection.documents.sort == "relevance"){
        filtered.sort((a, b) => parseFloat(b._score) - parseFloat(a._score));
   }

   // Sort documents
   if (this.user_selection.documents.sort == "date"){
        if (this.collection_name == "medline"){
            filtered.sort((a, b) => new Date(b[this.doc_source].entrez_date) - new Date(a[this.doc_source].entrez_date));        
        }
        else if (this.collection_name == "pmc" || this.collection_name == "suppdata"){
            filtered.sort((a, b) => new Date(b[this.doc_source].publication_date) - new Date(a[this.doc_source].publication_date));        
        }
        else {
            filtered.sort((a, b) => parseFloat(b[this.doc_source].pubyear) - parseFloat(a[this.doc_source].pubyear));
        }
   }

    // Filter facets
    var settings = {'user_selection': this.user_selection.documents.facets, 'filters': this.filters.documents.facets, 'dates': this.user_selection.documents.dates}
    filtered = filtered.filter(shared.filterThis.bind(this, settings))

    return filtered;

},

},
methods:{
...mapActions(['updateDocumentsFacet', 'updateDocumentsDates', 'updateDocumentsSort', 'updateDocuments']),
showAll(){
    this.per_page= this.processed_documents_list.length;
    this.current_page = 1
    this.show_size = true
    this.$matomo?.trackEvent("Documents displayed for "+this.collection_name, "all");
},
showSome(){
    this.per_page= this.per_page_init
    this.show_size = false
    this.$matomo?.trackEvent("Documents displayed for "+this.collection_name, "some");
},
goToTop(){
    //this.$router.push({ name: 'HomePage', query: { query: this.user_query.studies_search_term }}).catch(()=>{});
   // this.$router.push({ name: 'HomePage', hash: '#studies', query: { query: this.user_query.studies_search_term }}).catch(()=>{});
},
},
watch: {
    processed_documents_list: function () {
        if (this.current_page > this.page_total){
            this.current_page = 1;
        }
    },
    per_page: function () {
        this.goToTop()
    },
    current_page: function () {
        this.goToTop()
        this.$matomo?.trackEvent("Page change for "+this.collection_name, this.current_page);       
    },
},
}


</script>

<style scoped lang="scss">


.page-box {
display: flex;
justify-content: center;
}


</style>