<template>

    <div class="article-container row">

        <div class="col-12">

            <div class="row">

                <div class="col-1 left-column">
                    <span class="rank">{{ getRank }}</span><br/>
                </div>

                <div class="col-10 middle-column">

                    <h3>{{ answer.answer }}</h3>
                    <p v-html="highlighted_text"></p>


                </div>

                <div class="col-1 right-column">

                    <div class="article-menu">

                        <div class="row">
                            <Bookmark :bookmark_init="isInList" :collection_title="collection_title" :collection_name="collection_name" :doc_id="getUniqueId" :item="answer" type="qa" :position="getRank"/>
                        </div>

                        <div class="row">
                            <span class="score">score<br/>{{ getScore }}</span>
                        </div>

                        <div class="row">
                            <span class="expand">
                                <img src="../assets/images/icon_expand_on.png" alt="[+]" v-show="!expanded_status" @click="expandAnswer()"/>
                                <img src="../assets/images/icon_expand_off.png" alt="[-]" v-show="expanded_status"  @click="expandAnswer()"/>
                            </span>
                    </div>

                    </div>

                </div>

            </div>

            <div v-show="expanded_status" class="row more">

                <hr/>
                
                <ResultsSearchDocument :key="answer.docid"  :document_id="answer.docid" :collection_name="collection_name" :collection_title="collection_title" />
               
            </div>

        </div>



  </div>

</template>

<script>

    import { mapState } from 'vuex'
    import Bookmark from '@/components/Bookmark.vue'
    import ResultsSearchDocument from '@/components/ResultsSearchDocument.vue'
    import shared from '@/components/shared.js'

    export default {
      name: 'ResultsQaDocument',
      components: {
        Bookmark,
        ResultsSearchDocument
      },
      props: {
        answer: {
            type: Object,
            required: true
        },
        collection_name: {
            type: String,
            required: true
        },
        collection_title: {
            type: String,
            required: true
        },
        rank: {
            type: Number,
            default: 0,
        },
        page: {
            type: Number,
            default: 0,
        },
      },
      data (){
        return {
            bookmark_status: false,
            expanded_status: false,
        };
      },
      computed: {
        ...mapState(['user_query', 'user_selection', 'urls']),
        getRank(){
          return ((this.page-1)*10)+(this.rank+1)  
        },
        getUniqueId(){
            return this.answer.docid+"_"+this.answer.answer
        },
        highlighted_text(){
           var text_with_answer = this.answer.doc_text
           var start = this.answer.snippet_start
           var end = this.answer.snippet_end
           text_with_answer = text_with_answer.substring(0,start) + "<span  style='color:green;font-weight:bold'>" + text_with_answer.substring(start,end) + "</span>" + text_with_answer.substring(end);
           return (text_with_answer)
        },
        getScore() {
            return this.answer.answer_score.toFixed(2)
        },
        isInList(){
            if (this.collection_title in this.user_selection.selected_documents){
                if (this.getUniqueId in this.user_selection.selected_documents[this.collection_title]){
                    return true;
                }
            }
            return false;
        },
       
      },
      methods: {
        highlight(text){
            return(shared.highlight(text, this.user_query.highlight_terms))
        },
        expandAnswer(){
            if (this.expanded_status == false){
                this.$matomo?.trackEvent("Expand answer positions for "+this.collection_name, this.getRank);
                this.$matomo?.trackEvent("Expand answer", this.collection_name+":"+this.getRank+";"+this.answer.docid);
            }
            this.expanded_status = !this.expanded_status
        }
      },
    }

</script>

<style scoped lang="scss">

    .article-container {
        border: 5px #F2F2F2 solid;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) unset;
        border-collapse: separate;
        margin: 10px 0px;
        padding: 0px 0px;
    }

    .left-column {
      padding: 10px;
      background-color: #fff;
    }

    .middle-column {
      text-align: justify;
      padding: 0px 20px;
      background-color: #fff;
    }

    .right-column {
        background-color: #F2F2F2;
        text-align: center;
        padding: 0 5px 5px 5px;
    }

    .rank {
        background-color: #A2A2A2;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        min-width: 30px;
        padding: 0;
        border-radius: 20px;
    }

    .biblio, .resume, .more {
        font-size: 0.9em;
        text-align: justify
    }

    .more label {
        font-weight: bold;
    }

    .article-menu {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .score {
        font-weight: bold;
    }

    .expand {
        width: 100%;
    }

    .expand img {
        width: 20px;
    }

    .expand img:hover {
        cursor: pointer;
    }

    .link {
        color: #2c3e50;
    }

    .link:hover {
        color: #2c3e50;
    }

</style>
