<template>

    <div class="component-container query-container">

            <div class="row parameter-container">

              <div class="col-10 case">
                <input type="text" required v-model="user_query.original"/>
              </div>
              <div class="col-2 case">
                <ExecuteButton :disable=to_disable @click="searchSibils()" button_text="Search" :disabled="to_disable"/>
              </div>

            </div>

            <PanelHideShow :expanded_init="false" size="small" log_name="Advanced options">

             <template v-slot:title>Advanced options</template>
              <template v-slot:content>

                <QueryOptions/>

                <QueryAdvanced/>

              </template>

            </PanelHideShow>

            <PanelHideShow :expanded_init_examples="false" size="small" log_name="Examples">

              <template v-slot:title>Examples</template>
              <template v-slot:content>

                <QueryExample/>

              </template>

            </PanelHideShow>

    </div>

</template>


<script>
import QueryExample from '@/components/QueryExample.vue'
import QueryAdvanced from '@/components/QueryAdvanced.vue'
import QueryOptions from '@/components/QueryOptions.vue'
import { mapState, mapActions } from 'vuex'
import PanelHideShow from '@/components/PanelHideShow.vue'
import ExecuteButton from '@/components/ExecuteButton.vue'

    export default {
      name: 'QuerySearchBox',
      components: {
        QueryExample,
        QueryAdvanced,
        QueryOptions,
        PanelHideShow,
        ExecuteButton
      },
      data() {
        return {

        };
      },
      computed: {
        ...mapState(['user_query']),
        to_disable(){
            if (this.user_query.original != "" && (this.user_query.transformed == null || this.user_query.transformed == '')){
                return true
            }
            return false
        }
      },
      methods:{
        ...mapActions(['updateMode']),
        searchSibils(){
            this.$emit('searchSibils')
        },        
      },
      watch: {
          'user_query.transformation': function () {
              if (this.user_query.transformation == false){
                  this.user_query.normalization = false
              }
          },
          'user_query.original': function () {
             let text = this.user_query.original
             text = text.trim()
              if (text.endsWith('?')){
                  this.updateMode('qa')
              }
              else {
                this.updateMode('search')
              }
          },
      },
    }

</script>


<style scoped lang="scss">



    input[type="text"] {
      width: 100%;
      background: url("~@/assets/images/icon_search.png") #fff no-repeat 4px 5px;
      background-size: 30px;
      border: 1px solid grey;
      border-radius: 5px 0px 0px 5px;
      font-size: 1.2em;
      padding: 10px 10px 10px 50px;
   }
   .case{
    margin: 0px;
    padding: 0px;
   }

</style>
