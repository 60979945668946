<template>
    <div  class="banner" :style="cssVars">

        <div class="row">

            <div class="col-2">
                <img src="../assets/images/logo_banner.png" class="medium-img"/>
            </div>
            <div class="col-8">
                <h1>BiodiversityPMC</h1>
            </div>
            <div class="col-2">
            </div>

        </div>

    </div>

</template>


<script>
import { mapState } from 'vuex'

    export default {
      name: 'BannerComponent',
      computed: {
            ...mapState(['theme_color']),
            cssVars () {
                return{
                    '--color-main': this.theme_color.main,
                    '--color-secondary': this.theme_color.secondary,
                }
            }
      }
    }

</script>


<style scoped lang="scss">

    .banner{
        background: conic-gradient(at 0% 30%, var(--color-secondary) 10%, var(--color-main) 30%, var(--color-secondary) 50%);
        color: #FFFFFF;
        padding: 10px 20px 10px 20px;
        height: 105px;
        margin-bottom: 10px;
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .medium-img {
        height: 80px;
    }

</style>
