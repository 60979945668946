import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme_color: {
        main: "#CC0000",
        secondary: "#AF0C01",
    },
    doc_source: "_source",
    urls: {
        celltriage: process.env.VUE_APP_URL_CELLTRIAGE,
        search: process.env.VUE_APP_URL_SEARCH,
        fetch: process.env.VUE_APP_URL_FETCH,
        qa: process.env.VUE_APP_URL_QA,
        pmca: process.env.VUE_APP_URL_PMCA,
        sparql_endpoint: process.env.VUE_APP_URL_SPARQL_ENDPOINT,
        querytransformer: process.env.VUE_APP_URL_QUERY_TRANSFORMER,
        variomes: process.env.VUE_APP_URL_VARIOMES,
        biotxplorer: process.env.VUE_APP_URL_BIOTXPLORER,
    },
    pmca_parameters: {
        service: process.env.VUE_APP_SERVICE_PMCA,
    },
    collections: {
        medline: 'MEDLINE', 
        pmc: 'PMC+',
        plazi: 'PLAZI',
        suppdata: 'Supplementary data',
    },
    fields: {
        medline: "title,abstract,authors,entrez_date,pmid,publication_types,pmcid,doi,chemicals,keywords,mesh_terms,sup_mesh_terms,journal,annotations_str,annotations_material",
        pmc: "title,authors,article_type,subset,language,pmid,publication_date,pmc_release_date,doi,pmcid,publication_date,licence,journal,annotations_str,annotations_material",
        suppdata: "ext,text,pmcid,filename,pmid,subset,licence,title,publication_date,pmc_release_date,annotations_str,annotations_material",
        plazi: "article-title,treatment_title,text,zenodo-doi,treatment-bank-uri,publication-doi,annotations_str,annotations_material,nomenclature-taxon-name"
    },
    user_query: {
        mode: "search",
        mode_exec: "search",
        view: "week",
        pmcviewer: null,
        original: "",
        search: "",
        normalized_concepts: [],
        terms_status: [],
        normalized: "",
        transformed: null,
        transformation: true,
        normalization: true,
        search_size: 250,
        qa_size: 5,
        date_min: null,
        date_max: null,
        highlight_terms: [],
        tab_selected: 0,
        tab_selected_name: null,
        ids: '',
        collection: 'medline',
        collection_search: 'medline', 
        provider_search: ['medline', 'pmc', 'suppdata', 'plazi'],
        provider_name: null
    },
    search_results: {
        medline: [],
        pmc: [],
        plazi: [],
        suppdata: []
     },
     qa_results: {
        medline: [],
        pmc: [],
        plazi: [],
        suppdata: []
     },
    examples: {
        search_mode: [
            {
                query: "Halacarid mites",
                translated: "Halacarid mites"
            },
            {
                query: "Ixodiphagus hookeri",
                translated: "Ixodiphagus hookeri"
            },
            {
                query: "Microbial risk assessment for Salmonella enteritidis in eggs ",
                translated: "Microbial risk assessment for Salmonella enteritidis in eggs "
            },
            {
                query: "Recent (last month) articles about plant-pollinators",
                translated: "Plant-pollinators",
                date: 'month'
            }
        ],
        qa_mode: [
            {
                query: "What diseases are associated with ticks?",
                translated: "What diseases are associated with ticks?"
            },
            {
                query: "What is the gestation time of pangolins ?",
                translated: "What is the gestation time of pangolins ?"
            },
            {
                query: "What is the tail size of pangolins ?",
                translated: "What is the tail size of pangolins ?"
            },
            {
                query: "When is the sexual maturity of pangolins ?",
                translated: "When is the sexual maturity of pangolins ?"
            },
            {
                query: "Where potamopyrgus antipodarum are invasive ?",
                translated: "Where potamopyrgus antipodarum are invasive ?"
            },
            {
                query: "What species can be vector of eggs of Dermatobia hominis ?",
                translated: "What species can be vector of eggs of Dermatobia hominis ?"
            }
        ],
        other_mode: [
            {
                query: "Find all documents about POLG1 (R722H)",
                url: process.env.VUE_APP_URL_VARIOMES + "?genvars=POLG1%20(R722H)"
            },
            {
                query: "Find all biotic interactions between Phengaris arion and Myrmica sabuleti",
                url: process.env.VUE_APP_URL_BIOTXPLORER + "?species1=Phengaris%20arion&species2=Myrmica%20sabuleti&size=1"
            },
            {
                query: "Find all annotations of PMC2196267",
                url: process.env.VUE_APP_URL_SPARQL_ENDPOINT + "?default-graph-uri=&qtxt=PREFIX%20%3A%20%3Chttp%3A%2F%2Fsibils.org%2Frdf%23%3E%0APREFIX%20sibils%3A%20%3Chttp%3A%2F%2Fsibils.org%2Frdf%2Fdata%2F%3E%0APREFIX%20rdf%3A%20%3Chttp%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%3E%0APREFIX%20rdfs%3A%20%3Chttp%3A%2F%2Fwww.w3.org%2F2000%2F01%2Frdf-schema%23%3E%0APREFIX%20skos%3A%20%3Chttp%3A%2F%2Fwww.w3.org%2F2004%2F02%2Fskos%2Fcore%23%3E%0A%0ASELECT%0A%20%20(str(%3Fdoi)%20as%20%3Fdoi)%0A%20%20(str(%3Fcpt_id)%20as%20%3Fcpt_id)%0A%20%20(str(%3Fscheme)%20as%20%3Fscheme)%0A%20%20(str(%3Fstart)%20as%20%3Fstart)%0A%20%20(str(%3Ftoken)%20as%20%3Ftoken)%0A%20%20(str(%3Fpart)%20as%20%3Fsection)%0A%20%20(str(%3Fpart_type)%20as%20%3Fpart_type)%0AWHERE%20%7B%0A%20%20VALUES%20%3Fpubli%20%7B%20sibils%3APMC2196267%20%7D%20.%0A%20%20%3Fpubli%20%3Adoi%20%3Fdoi.%0A%20%20%3Fpubli%20%3AhasAnnotation%20%3Fa%20.%0A%20%20%3Fa%20%3AhasBody%20%3Fcpt%20.%0A%20%20%3Fcpt%20skos%3Anotation%20%3Fcpt_id%20.%0A%20%20%3Fcpt%20skos%3AinScheme%20%3Fscheme_IRI%20.%0A%20%20%3Fscheme_IRI%20%20rdfs%3Alabel%20%3Fscheme%20.%0A%0A%20%20%3Fa%20%3AhasTarget%20%3Ftrg.%0A%20%20%3Ftrg%20%3AhasSource%20%3Fpart.%0A%20%20%3Fpart%20a%20%3Fpart_type%20.%0A%0A%20%20%3Ftrg%20%3AhasSelector%20%3Fsel%20.%0A%20%20%3Fsel%20%3Astart%20%3Fstart.%0A%20%20%3Fsel%20%3Aexact%20%3Ftoken.%0A%20%20%23%20FILTER%20(%3Fpart_type%20!%3D%20%3AParagraph%20)%0A%7D%0AORDER%20BY%20%3Fpart%20%3Fstart%20%3Ftoken&format=text%2Fhtml&should-sponge=&timeout=0&signal_void=on"
            },


        ]
        
    },
    user_selection: {
        documents: {
            dates: {
                min: null,
                max: null
            },
             facets: {
                gene: [],
                disease: [],
                drug: [],
                cellosaurus: [],
                species: [],
                biological_process: [],
                cellular_component: [],
                molecular_function: [],
                evidence: [],
                ppi_ptm: [],
                chemical: [],
                ott: [],
                robi: [],
                mesh: [],
                journal: [],
                publication_types: [],
                article_type: [],
                keywords: [],
                chemicals: [],
                mesh_terms: [],
                subset: [],
                ext: [],
                licence: [],
                language: []
            },
            sort: 'relevance',
            highlights: []
        },
        selected_documents: {},
        selected_documents_count: 0
    },
    filters: {
        documents: {
            sort: ['relevance', 'date'],
            facets: [
                //{title: 'Date', short: 'dates', multi: false},
                // {title: 'Genes', short: 'gene', multi: false},
                //{title: 'Diseases', short: 'disease', multi: false},
                //{title: 'Drugs', short: 'drug', multi: false},
                {title: 'Cellosaurus', short: 'cellosaurus', multi: false},
                {title: 'Species', short: 'species', multi: false},
                // {title: 'Biological processes', short: 'biological_process', multi: false},
                // {title: 'Cellular components', short: 'cellular_component', multi: false},
                // {title: 'Molecular functions', short: 'molecular_function', multi: false},
                // {title: 'Evidences', short: 'evidence', multi: false},
                // {title: 'PPI-PTM', short: 'ppi_ptm', multi: false},
                // {title: 'Chemicals', short: 'chemical', multi: false},
                // {title: 'MeSH terms', short: 'mesh', multi: false},
                {title: 'Species', short: 'ott', multi: false},
                {title: 'Biotic interactions', short: 'robi', multi: false},
                {title: 'Journals', short: 'journal', multi: true},
                {title: 'Publication types', short: 'publication_types', multi: true},
                {title: 'Article types', short: 'article_type', multi: true},
                {title: 'PMC set', short: 'subset', multi: true},
                //{title: 'Keywords (NLM)', short: 'keywords', multi: false},
                {title: 'Chemicals (NLM)', short: 'chemicals', multi: false},
                {title: 'MeSH terms (NLM)', short: 'mesh_terms', multi: false}, 
                {title: 'File types', short: 'ext', multi: true}, 
                {title: 'Licences', short: 'licence', multi: true}, 
                {title: 'Languages', short: 'language', multi: true}, 
            ],
            highlights: [],
        }
    },
  },
  mutations: {
    UPDATE_DOC_SOURCE(state, value) {
        state.doc_source = value
    },
    UPDATE_QUERY_ORIGINAL(state, value) {
        state.user_query.original = value
    },
    UPDATE_SEARCH_SIZE(state, value) {
        state.user_query.search_size = value
    },
    UPDATE_QA_SIZE(state, value) {
        state.user_query.qa_size = value
    },
    UPDATE_QUERY_NORMALIZATION(state, value) {
        state.user_query.normalization = value
    },
    UPDATE_QUERY_TRANSFORMATION(state, value) {
        state.user_query.transformation = value
    },
    UPDATE_QUERY_DATE_MIN(state, value) {
        state.user_query.date_min = value
    },
    UPDATE_QUERY_DATE_MAX(state, value) {
        state.user_query.date_max = value
    },
    UPDATE_QUERY_SEARCH(state, value) {
        state.user_query.search = value
    },
    UPDATE_QUERY_NORMALIZED(state, value) {
        state.user_query.normalized = value
    },
    UPDATE_QUERY_PMCVIEWER(state, value) {
        state.user_query.pmcviewer = value
    },
    UPDATE_HIGHLIGHT_TERMS(state, value) {
        state.user_query.highlight_terms = [...state.user_query.highlight_terms, ...value];
    },
    UPDATE_MODE(state, value) {
        state.user_query.mode = value
    },
    UPDATE_PROVIDER_SEARCH(state, value) {
        state.user_query.provider_search = value
    },
    UPDATE_PROVIDER_NAME(state, value) {
        state.user_query.provider_name = value
    },
    UPDATE_TAB(state, value) {
        state.user_query.tab_selected = value
    },
    UPDATE_TAB_NAME(state, value) {
        state.user_query.tab_selected_name = value
    },
    UPDATE_IDS(state, value) {
        state.user_query.ids = value
    },
    UPDATE_COLLECTION(state, value) {
        state.user_query.collection = value
    },
    UPDATE_COLLECTION_SEARCH(state, value) {
        state.user_query.collection_search = value
    },
    UPDATE_MODE_EXEC(state, value) {
        state.user_query.mode_exec = value
    },
    CLEAR_HIGHLIGHT_TERMS(state) {
        state.user_query.highlight_terms = [];
    },
    UPDATE_QUERY_CONCEPTS_NORMALIZED(state, value) {
        state.user_query.normalized_concepts = value
    },
    UPDATE_QUERY_TERMS_STATUS(state, value) {
        state.user_query.terms_status = value
    },
    UPDATE_QUERY_TRANSFORMED(state, value) {
        state.user_query.transformed = value
    },
    UPDATE_SEARCH_RESULTS(state, value) {
        state.search_results[value.collection] = value.results
    },
    UPDATE_QA_RESULTS(state, value) {
        state.qa_results[value.collection] = value.results
    },
    UPDATE_DOCUMENTS_SORT(state, value){
        state.user_selection.documents.sort = value
    },
    UPDATE_DOCUMENTS_FACET(state, value){
        state.user_selection.documents.facets[value.facet] = value.list
    },
    UPDATE_DOCUMENTS_DATES(state, value){
        state.user_selection.documents.dates[value.type] = value.date
    },
    UPDATE_SELECTED_PUBLICATIONS(state, value){
        state.user_selection.selected_documents = value
    },
    UPDATE_DOCUMENT_COUNT(state, value){
        state.user_selection.selected_documents_count = value
    },
    UPDATE_INIT_DATE_FILTER(state, value) {
        if (state.user_selection.documents.facets.date.length > 0){
            if (state.user_selection.documents.facets.date[0] > value.dates[0]){
                state.user_selection.documents.facets.date[0] = value.dates[0]
            }
            if (state.user_selection.documents.facets.date[1] < value.dates[1]){
                state.user_selection.documents.facets.date[1] = value.dates[1]
            }
        }
        else {
            state.user_selection.documents.facets.date = value.dates
        }
        if (state.filters.documents.date.length > 0){
            if (state.filters.documents.date[0] > value.dates[0]){
                state.filters.documents.date[0] = value.dates[0]
            }
            if (state.filters.documents.date[1] < value.dates[1]){
                state.filters.documents.date[1] = value.dates[1]
            }
        }
        else {
            state.filters.documents.date = value.dates
        }
    },
  },
  actions: {
    updateDocSource(context, value) {
        context.commit('UPDATE_DOC_SOURCE', value)
     },
    updateQueryOriginal(context, value) {
       context.commit('UPDATE_QUERY_ORIGINAL', value)
    },
    updateQaSize(context, value) {
        context.commit('UPDATE_QA_SIZE', value)
     },
     updateSearchSize(context, value) {
        context.commit('UPDATE_SEARCH_SIZE', value)
     },
     updateQueryNormalization(context, value) {
        context.commit('UPDATE_QUERY_NORMALIZATION', value)
     },
     updateQueryTransformation(context, value) {
        context.commit('UPDATE_QUERY_TRANSFORMATION', value)
     },
     updateQueryDateMin(context, value) {
        context.commit('UPDATE_QUERY_DATE_MIN', value)
     },
     updateQueryDateMax(context, value) {
        context.commit('UPDATE_QUERY_DATE_MAX', value)
     },
     updateQueryPmcviewer(context, value) {
        context.commit('UPDATE_QUERY_PMCVIEWER', value)
     },
    updateQuerySearch(context, value) {
        context.commit('UPDATE_QUERY_SEARCH', value)
     },
    updateNormalizedQuery(context, value) {
        context.commit('UPDATE_QUERY_NORMALIZED', value)
    },
    updateHighlightTerms(context, value) {
        context.commit('UPDATE_HIGHLIGHT_TERMS', value)
    },
    updateMode(context, value) {
        context.commit('UPDATE_MODE', value)
    },
    updateTab(context, value) {
        context.commit('UPDATE_TAB', value)
    },
    updateTabName(context, value) {
        context.commit('UPDATE_TAB_NAME', value)
    },
    updateProviderSearch(context, value) {
        context.commit('UPDATE_PROVIDER_SEARCH', value)
    },
    updateProviderName(context, value) {
        context.commit('UPDATE_PROVIDER_NAME', value)
    },
    updateIds(context, value) {
        context.commit('UPDATE_IDS', value)
    },
    updateCollection(context, value) {
        context.commit('UPDATE_COLLECTION', value)
    },
    updateCollectionSearch(context, value) {
        context.commit('UPDATE_COLLECTION_SEARCH', value)
    },
    updateModeExec(context, value) {
        context.commit('UPDATE_MODE_EXEC', value)
    },
    clearHighlightTerms(context) {
        context.commit('CLEAR_HIGHLIGHT_TERMS')
    },
    updateQueryConceptsNormalized(context, value){
        context.commit('UPDATE_QUERY_CONCEPTS_NORMALIZED', value)
    },
    updateQueryTermsStatus(context, value){
        context.commit('UPDATE_QUERY_TERMS_STATUS', value)
    },
    updateTransformedQuery(context, value) {
        context.commit('UPDATE_QUERY_TRANSFORMED', value)
    },
    updateSearchResults(context, value) {
        context.commit('UPDATE_SEARCH_RESULTS', value)
     },
     updateQaResults(context, value) {
        context.commit('UPDATE_QA_RESULTS', value)
     },
     updateDocumentsSort(context, value) {
        context.commit('UPDATE_DOCUMENTS_SORT', value)
    },
    updateDocumentsFacet(context, value) {
        context.commit('UPDATE_DOCUMENTS_FACET', value)
    },
    updateDocumentsDates(context, value) {
        context.commit('UPDATE_DOCUMENTS_DATES', value)
    },
    addBookmarkedPublication(context, data) {
        var publications = this.state.user_selection.selected_documents
        if (!(data.query in publications)){
            publications[data.query] = {}
        }
        if (!(data.collection in publications[data.query])){
            publications[data.query][data.collection] = {}
        }
        if (!(data.type in publications[data.query][data.collection])){
            publications[data.query][data.collection][data.type] = {}
        }
        publications[data.query][data.collection][data.type][data.document] = data.item
        context.commit('UPDATE_SELECTED_PUBLICATIONS', publications)
        context.commit('UPDATE_DOCUMENT_COUNT', this.state.user_selection.selected_documents_count + 1)
    },
    removeBookmarkedPublication(context, data) {
        var publications = this.state.user_selection.selected_documents
        delete publications[data.query][data.collection][data.type][data.document]
        if (Object.keys(publications[data.query][data.collection][data.type]).length == 0){
            delete publications[data.query][data.collection][data.type]
        }
        if (Object.keys(publications[data.query][data.collection]).length == 0){
            delete publications[data.query][data.collection]
        }
        if (Object.keys(publications[data.query]).length == 0){
            delete publications[data.query]
        }
        context.commit('UPDATE_SELECTED_PUBLICATIONS', publications)
        context.commit('UPDATE_DOCUMENT_COUNT', this.state.user_selection.selected_documents_count - 1)
    },
    updateInitDateFilter(context, value){
        context.commit('UPDATE_INIT_DATE_FILTER', value)
     },
  },
  modules: {
  }
})
