<template>

  <b-tab title-item-class="tab-title" :active="condition" @click="onTabChange">

      <template slot="title">
        <div class="title-container">
          <span class="title-span">{{ collection_title }}</span>
          <span v-if="in_progress"><PulseLoader :color="theme_color.main" size="5px"/></span>
          <span v-else>
             ({{ total }} answers)
          </span>
        </div>
      </template>

      <b-card-text>

          <div v-if="in_progress" class="progress-container">
            <PulseLoader :color="theme_color.main"/>
          </div>

          <div v-else class="content-container">

            <div class="full-container">

              <div class="error-container" v-if="error">{{ error }}</div>

              <ResultsQaDocuments v-else :answers="qa_results[collection_name]" :collection_name="collection_name" :collection_title="collection_title"/>

            </div> 

          </div>

      </b-card-text>

  </b-tab>

</template>

<script>

  import { mapState, mapActions } from 'vuex'
  import axios from 'axios';
  import ResultsQaDocuments from '@/components/ResultsQaDocuments.vue'
  var PulseLoader = require('vue-spinner/src/PulseLoader.vue').default;

  export default {
    name: 'ResultsQaCollection',
    components: {
      PulseLoader,
      ResultsQaDocuments
    },
    props: {
      collection_name: {
          type: String,
          required: true
      },
      collection_title: {
          type: String,
          required: true
      },
    },
    data() {
        return {
          in_progress: false,
          total: null,
          error: null,
          condition: false
        };
      },
    computed: {
      ...mapState(['urls', 'user_query', 'qa_results', 'theme_color']),
      cssVars () {
        return{
           '--color-main': this.theme_color.main,
        }
      },
      
    },
    methods: {
      ...mapActions(['updateQaResults', 'updateTab', 'updateTabName']),
      search(){
        if(this.user_query.tab_selected_name == this.collection_name){
          this.updateTab(this.tab)
          this.condition = true
        }
        this.total = null
        this.in_progress = true
        this.updateQaResults({'collection': this.collection_name, 'results': []})
        var url = this.urls['qa']
        var final_query = {}
        if ((this.user_query.date_min != null ||  this.user_query.date_max != null) && this.collection_name != "plazi"){
          var date_names = []
          if (this.collection_name == "medline"){
            date_names = ["entrez_date"]
          }
          else if (this.collection_name == "pmc"){
            date_names = ["pmc_release_date", "publication_date"]
          }
          var ranges_query = {}
          ranges_query['bool'] = {}
          ranges_query['bool']['should'] = []
          for (var r=0; r<date_names.length; r++){
              var date_name = date_names[r]
              var range_query = {}
              range_query['range'] = {}
              range_query['range'][date_name] = {}
              if (this.user_query.date_min != null){
                range_query['range'][date_name]['gte'] = this.user_query.date_min
              }
              if (this.user_query.date_max != null){
                range_query['range'][date_name]['lte'] = this.user_query.date_max
            }
            ranges_query['bool']['should'].push(range_query)
          }
          
          final_query['query'] = {}
          final_query['query']['bool'] = {}
          final_query['query']['bool']['must'] = []
          final_query['query']['bool']['must'].push(this.user_query.transformed.query)
          final_query['query']['bool']['must'].push(ranges_query)

        }
        else {
            final_query = this.user_query.transformed
        }
        this.cancel();
        let axiosSource = axios.CancelToken.source();
        this.request = { cancel: axiosSource.cancel };
        let body = {'jq': JSON.stringify(final_query), 'col': this.collection_name, 'n': this.user_query.qa_size, 'q': this.user_query.original}
        axios
        .post(url, body, {
          cancelToken: axiosSource.token,
        })
            .then(response => {
              if ('success' in response.data && response.data.success == false){
                this.error = response.data.error
                this.total = 0
              }
              else {
                this.error = null
                console.log(response.data)
                var answers = response.data.answers
                this.total = answers.length
                this.updateQaResults({'collection': this.collection_name, 'results': answers})
                this.selectTab()
              }
            })
            .catch(error => {
              if(error != "Cancel"){
                alert ("Failed to search sibils qa: "+error)
              }
            })
            .finally(() => this.in_progress = false)
      },
      cancel() {
        this.updateQaResults({'collection': this.collection_name, 'results': []})
        if (this.request) this.request.cancel();
      },
      selectTab() {
        if (this.user_query.tab_selected_name == null){
          if (this.user_query.tab_selected == this.tab && this.qa_results[this.collection_name].length == 0){
              this.updateTab(0)
              this.condition = false
          }
          else if ((this.user_query.tab_selected == 0 && this.qa_results[this.collection_name].length > 0) || this.user_query.tab_selected == this.tab){
              this.updateTab(this.tab)
              this.condition = true
          }
          else {
            this.condition = false
          }
        }
      },
      onTabChange(){
          this.$matomo?.trackEvent("Display collection qa", this.collection_name);
          this.updateTabName(this.collection_name)
          var newUrl = new URL(window.location.href);
          newUrl.searchParams.set('tab', this.collection_name);
          window.history.replaceState({}, document.title, newUrl.toString());
      },
    }
  }

</script>

<style scoped lang="scss">

.progress-container {
    text-align: center;
    width: 100%;
    padding: 50px;
  }

  .content-container {
      display: flex;
  }

  .facets-container {
      float: left;
      margin-right: 20px;
  }

  .full-container {
      width: 100%;
      margin: 0 auto;
  }

    .title-container{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title-span {
      padding-right: 10px;
    }

    .error-container{
      background-color: lightcoral;
      padding: 10px;
      color: #fff;
    }

 
</style>
