<template>

    <div>
    
            <p>
                (Total: {{ answers.length }}
                    <span v-if="answers.length <= 1">answer</span>
                    <span v-if="answers.length > 1">answers</span>)
            </p>

            <div v-if="answers.length > 0">

                <ResultsQaDocument v-for="(answer, index) in answers.slice(item_min, item_max)" :key="'answer_'+collection_name+'_'+index" :answer="answer" :rank="index"  :collection_name="collection_name" :collection_title="collection_title" :page="current_page"/>

                <br/>

                <div class="page-box">
                    <v-pagination v-model="current_page" :page-count="page_total" :classes="bootstrapPaginationClasses" :labels="paginationAnchorTexts"></v-pagination>
                </div>

                <p v-if="!show_size && answers.length > per_page"><a @click="showAll()">Show all answers</a></p>
                <p v-if="show_size && answers.length > per_page_init"><a @click="showSome()">Show {{ per_page_init }} answers per page</a></p>

                <br/>

            </div>



    </div>

</template>

<script>

import { mapState, mapActions } from 'vuex'
import vPagination from 'vue-plain-pagination'
import ResultsQaDocument from '@/components/ResultsQaDocument.vue'

export default {
name: 'ResultsQaDocuments',
components: {
vPagination,
ResultsQaDocument
},
props: {
        answers: {
            type: Array,
            required: true
        },
        collection_name: {
            type: String,
            required: true
        },
        collection_title: {
            type: String,
            required: true
        },
      },
data() {
return {
    in_progress: true,
    current_page: 1,
    per_page_init: 20,
    per_page: 20,
    show_size: false,
    bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
    },
    paginationAnchorTexts: {
        first: '<<',
        prev: '<',
        next: '>',
        last: '>>'
    }
};
},
computed: {
...mapState(['urls', 'user_selection',]),
page_total(){
   return Math.ceil(this.answers.length/this.per_page)
},
item_min(){
    return ((this.current_page-1)*this.per_page)
},
item_max(){
    return ((this.current_page*this.per_page))
},

},
methods:{
...mapActions(['updateDocumentsFacet', 'updateDocumentsDates', 'updateDocumentsSort', 'updateDocuments']),
showAll(){
    this.per_page= this.processed_documents_list.length;
    this.current_page = 1
    this.show_size = true
    this.$matomo?.trackEvent("Answers displayed for "+this.collection_name, "all");

},
showSome(){
    this.per_page= this.per_page_init
    this.show_size = false
    this.$matomo?.trackEvent("Answers displayed for "+this.collection_name, "all");

},
goToTop(){
    //this.$router.push({ name: 'HomePage', query: { query: this.user_query.studies_search_term }}).catch(()=>{});
   // this.$router.push({ name: 'HomePage', hash: '#studies', query: { query: this.user_query.studies_search_term }}).catch(()=>{});
},
},
watch: {
    answers: function () {
        if (this.current_page > this.page_total){
            this.current_page = 1;
        }
    },
    per_page: function () {
        this.goToTop()
    },
    current_page: function () {
        this.goToTop()
    },
},
}


</script>

<style scoped lang="scss">


.page-box {
display: flex;
justify-content: center;
}


</style>