<template>

  <div class="parameters-container">
 
      <div v-if="user_query.mode=='search'" class="parameter-container">
          <label class="long_label" for="normalization">Number of results for the search mode</label>
          <input type="number" min="1" max="10000" v-model="user_query.search_size" @change="logNumber(user_query.search_size)">
        </div>
        <div v-else class="parameter-container">
          <label class="long_label" for="normalization">Number of results for the QA mode</label>
          <input type="number" min="1" max="50" v-model="user_query.qa_size" @change="logNumber(user_query.qa_size)">
        </div>
      <div class="parameter-container">
        <table>
          <tr>
            <td><label>Date range<sup>*</sup></label></td>
            <td>from</td>
            <td><datepicker v-model="user_query.date_min" :disabled-dates="disableMin" format="yyyy-MM-dd" input-class="my-picker-class2" @selected="logDateChange('min', $event)"></datepicker></td>
            <td>to</td>
            <td><datepicker v-model="user_query.date_max" :disabled-dates="disableMax" format="yyyy-MM-dd" input-class="my-picker-class2" @selected="logDateChange('max', $event)"></datepicker></td>
            <td><a @click="fillDate('year')">last year</a></td>
            <td><a @click="fillDate('month')">last month</a></td>
            <td><a @click="fillDate('week')">last week</a></td>
          </tr>
        </table>
        <p><i><sup>*</sup>For MEDLINE and PMC collections.</i></p>
      </div>

      <div class="parameter-container">
          <input type="checkbox" id="transformation" v-model="user_query.transformation" @change="logTransformation()">
          <label for="transformation">Enable automatic transformation of the query</label>
      </div>

      <div class="parameter-container">
          <input type="checkbox" id="normalization" v-model="user_query.normalization" @change="logNormalization()">
          <label for="normalization">Enable automatic normalization of the query</label>
        </div>

  </div>

</template>


<script>
import { mapState } from 'vuex'
import Datepicker from 'vuejs-datepicker';


  export default {
    name: 'QueryAdvanced',
    components: {
      Datepicker
    },
    data() {
      return {
      };
    },
    computed: {
      ...mapState(['user_query', 'theme_color']),
      cssVars () {
        return{
           '--color-main': this.theme_color.main,
        }
      },
      disableMin: function() {
            return {
                from: this.user_query.date_max,
            };
        },
        disableMax: function() {
            return {
                to: this.user_query.date_min,
            };
        },
    },
    methods:{
      fillDate(limit){
        this.$matomo?.trackEvent("Options date range", limit);
        const now = new Date();
        var minDate = new Date();
        this.user_query.date_max = now
        if (limit == "year"){
          minDate = minDate.setFullYear(minDate.getFullYear() - 1);
        }
        else if (limit == "month"){
          minDate = minDate.setMonth(minDate.getMonth() - 1);      
        }
        else if (limit == "week"){
          minDate = minDate.setDate(minDate.getDate() - 7);

        }
          let final_date = new Date(minDate)
          this.user_query.date_min = final_date
      },
      logNumber(number){
        this.$matomo?.trackEvent("Options number of results", number);
      },
      logTransformation(){
        this.$matomo?.trackEvent("Options transformation", "choice:"+this.user_query.transformation.toString());
      },
      logNormalization(){
        this.$matomo?.trackEvent("Options normalization", "choice:"+this.user_query.normalization.toString());
      },
      logDateChange(type, date){
        var date_formatted = date.toISOString().split('T')[0]
        this.$matomo?.trackEvent("Options date "+type+" selection", date_formatted);
      }
    },
    watch: {
          'user_query.transformation': function () {
              if (this.user_query.transformation == false){
                  this.user_query.normalization = false
              }
          },
          'user_query.normalization': function () {
              if (this.user_query.normalization == true){
                  this.user_query.transformation = true
              }
          }
      },

  }

</script>


<style scoped lang="scss">

.parameters-container {
    padding-top: 20px;
    padding-bottom: 20px;
        text-align: left
    }
    .parameter-container {
        padding: 0 0 20px 0;
    }

    label {
        font-weight: bold;
    }

   input[type="number"] {
      width: 100px;
      margin-left: 10px;
      border: 1px solid grey;
      border-radius: 5px;
   }

   input[type="checkbox"] {

      margin-right: 10px;
      border: 1px solid grey;
   }

   td {
    padding: 0 10px 0 0;
   }

   .long_label {
    width: 250px;
   }


</style>

<style>
    .my-picker-class2{
        width: 100px;
    }
</style>