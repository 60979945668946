<template>

    <div>
        <img v-show="bookmark" src="../assets/images/icon_bookmark_yes.png" alt="[+]" title="Remove this publication from your bookmarks" @click="removeBookmark()"/>
        <img v-show="!bookmark" src="../assets/images/icon_bookmark_no.png" alt="[-]" title="Add this publication to your bookmarks" @click="addBookmark()"/>
    </div>

</template>

<script>

    import { mapState, mapActions } from 'vuex'

    export default {
      name: 'Bookmark',
      props: {
        bookmark_init: {
            type: Boolean,
            default: false
        },
        collection_name: {
            type: String,
            required: true
        },
        collection_title: {
            type: String,
            required: true
        },
        doc_id: {
            type: String,
            required: true
        },
        item : {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: "search"
        },
        position: {
            type: Number,
            default: null
        }
      },
      data() {
        return {
            bookmark: this.bookmark_init,
        };
      },
      computed: {
        ...mapState(['user_query']),
      },
      methods: {
        ...mapActions(['addBookmarkedPublication', 'removeBookmarkedPublication']),
        addBookmark(){
           var data = {}
           data.collection = this.collection_title
           data.document = this.doc_id
           data.item = this.item
           data.query = this.user_query.original
           data.type = this.type
           this.addBookmarkedPublication(data)
           this.bookmark = true
           this.$matomo?.trackEvent("Bookmark added", this.collection_name+";"+this.doc_id+";"+this.position);
       },
       removeBookmark(){
           var data = {}
           data.collection = this.collection_title
           data.document = this.doc_id
           data.query = this.user_query.original
           data.type = this.type
           this.removeBookmarkedPublication(data)
           this.bookmark = false
           this.$matomo?.trackEvent("Bookmark removed", this.collection_name+";"+this.doc_id+";"+this.position);

       },
      }
    }

</script>

<style scoped lang="scss">

    img {
        width: 15px;
        cursor: pointer;
    }

</style>